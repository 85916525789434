import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentTcName,
  selectCurrentToken,
} from "../../features/auth/authSlice";
import { getTcName } from "../../utils/getFormattedToken";
import { FormRadioInput, FormInput } from "../form";
import { selectCurrentJurisdiction } from "../../features/auth/authSlice";
import { fecthGenActivities } from "../../utils/apiContext";
import {
  selectGenActivitiesPost,
  setGenActivitiesPost,
} from "../../features/auth/isPostMade";

const ModalGeneralActivitiesForm = ({ open, handleClose }) => {
  const modalRef = useRef(null);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const jurisdiction = useSelector(selectCurrentJurisdiction);

  const tc_name = useSelector(selectCurrentTcName);

  const [procurement_of_project_vehicle, setProcurementOfProjectVehicle] =
    useState("");
  const [
    renovation_of_ideas_project_office,
    setRenovationOfIdeasProjectOffice,
  ] = useState("");
  const [
    procurement_of_office_furniture_and_equipment_for_ideas_office,
    setProcurementOfOfficeFurnitureAndEquipmentForIdeasOffice,
  ] = useState("");
  const [procurement_of_diesel_generator, setProcurementOfDieselGenerator] =
    useState("");
  const [
    provision_of_solar_powered_borehole_and_water_reticulation,
    setProvisionOfSolarPoweredBoreholeAndWaterReticulation,
  ] = useState("");
  const [
    installation_of_solar_power_system,
    setInstallationOfSolarPowerSystem,
  ] = useState("");
  const [
    installation_of_solar_powered_streetlights,
    setInstallationOfSolarPoweredStreetlights,
  ] = useState("");
  const [
    procurement_and_installations_of_ICT_equipment_internet_infrastructure,
    setProcurementAndInstallationsOfICTEquipmentInternetInfrastructure,
  ] = useState("");
  const [
    renovation_and_upgrading_of_toilet_facility,
    setRenovationAndUpgradingOfToiletFacility,
  ] = useState("");
  const [renovation_of_ICT_laboratory, setRenovationOfICTLaboratory] =
    useState("");
  const [gbv_sensitization, setGbvSensitization] = useState("");
  const [tvet_sensitization, setTvetSensitization] = useState("");
  const [renovation_of_classrooms, setRenovationOfClassrooms] = useState("");
  const [renovation_of_college_library, setRenovationOfCollegeLibrary] =
    useState("");

  const dispatch = useDispatch();
  const isGenActivitiesPost = useSelector(selectGenActivitiesPost);

  const {
    data: allGenActivities,
    isLoading: genActivitesLoading,
    error,
  } = useQuery({
    queryKey: ["generalActivitiesData"],
    queryFn: () => fecthGenActivities(jurisdiction),
  });
  console.log("allGenActivities", allGenActivities);

  useEffect(() => {
    if (allGenActivities && tc_name) {
      const filteredData = allGenActivities.find(
        (post) => post.tc_name === tc_name
      );

      if (filteredData) {
        setProcurementOfProjectVehicle(
          filteredData.procurement_of_project_vehicle || ""
        );
        setRenovationOfIdeasProjectOffice(
          filteredData.renovation_of_ideas_project_office || ""
        );
        setProcurementOfOfficeFurnitureAndEquipmentForIdeasOffice(
          filteredData.procurement_of_office_furniture_and_equipment_for_ideas_office ||
            ""
        );
        setProcurementOfDieselGenerator(
          filteredData.procurement_of_diesel_generator || ""
        );
        setProvisionOfSolarPoweredBoreholeAndWaterReticulation(
          filteredData.provision_of_solar_powered_borehole_and_water_reticulation ||
            ""
        );
        setInstallationOfSolarPowerSystem(
          filteredData.installation_of_solar_power_system || ""
        );
        setInstallationOfSolarPoweredStreetlights(
          filteredData.installation_of_solar_powered_streetlights || ""
        );
        setProcurementAndInstallationsOfICTEquipmentInternetInfrastructure(
          filteredData.procurement_and_installations_of_ICT_equipment_internet_infrastructure ||
            ""
        );
        setRenovationAndUpgradingOfToiletFacility(
          filteredData.renovation_and_upgrading_of_toilet_facility || ""
        );
        setRenovationOfICTLaboratory(
          filteredData.renovation_of_ICT_laboratory || ""
        );
        setGbvSensitization(filteredData.gbv_sensitization || "");
        setTvetSensitization(filteredData.tvet_sensitization || "");
        setRenovationOfClassrooms(filteredData.renovation_of_classrooms || "");
        setRenovationOfCollegeLibrary(
          filteredData.renovation_of_college_library || ""
        );
      }
    }
  }, [allGenActivities, tc_name]);

  const isUpdate = allGenActivities?.some((post) => post?.tc_name === tc_name);

  // /update-general-activities

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      await axiosInstance.post(`/ga/c1/make-general-activities`, formData);
    },
    onSuccess: () => {
      toast.success("Successful");
      queryClient.invalidateQueries(["generalActivitiesData"]);
      setIsLoading(false);
      handleClose();
    },
    onError: (err) => {
      if (err.response && err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An error occurred");
      }
      setIsLoading(false);
      handleClose();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const updatedEditPost = {
        ...formData,
        jurisdiction: jurisdiction,
        name_of_college: tc_name,
      };
      const response = await axiosInstance.put(
        `/ga/c1/update-general-activities?jurisdiction=${jurisdiction}`,
        updatedEditPost
      );
      return response.data;
    },
    onSuccess: (response) => {
      handleSuccess(response);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const handleSuccess = (response) => {
    isUpdate
      ? toast.success("Updated Successfully")
      : toast.success("Successful");
    setIsLoading(false);

    dispatch(setGenActivitiesPost(true));

    handleClose();
  };

  const handleError = (err) => {
    if (err.response && err.response.status === 400) {
      toast.error("Post already exists");
    } else {
      toast.error("An error occurred");
    }
    setIsLoading(false);
    handleClose();
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      procurement_of_project_vehicle: procurement_of_project_vehicle,
      renovation_of_ideas_project_office: renovation_of_ideas_project_office,
      procurement_of_office_furniture_and_equipment_for_ideas_office:
        procurement_of_office_furniture_and_equipment_for_ideas_office,
      procurement_of_diesel_generator: procurement_of_diesel_generator,
      provision_of_solar_powered_borehole_and_water_reticulation:
        provision_of_solar_powered_borehole_and_water_reticulation,
      installation_of_solar_power_system: installation_of_solar_power_system,
      installation_of_solar_powered_streetlights:
        installation_of_solar_powered_streetlights,
      procurement_and_installations_of_ICT_equipment_internet_infrastructure:
        procurement_and_installations_of_ICT_equipment_internet_infrastructure,
      renovation_and_upgrading_of_toilet_facility:
        renovation_and_upgrading_of_toilet_facility,
      renovation_of_ICT_laboratory: renovation_of_ICT_laboratory,
      gbv_sensitization: gbv_sensitization,
      tvet_sensitization: tvet_sensitization,
      renovation_of_classrooms: renovation_of_classrooms,
      renovation_of_college_library: renovation_of_college_library,
      tc_name: tc_name,
      jurisdiction,
    };

    if (isUpdate) {
      updateMutation.mutate(formData, {
        onSuccess: (data) => {
          console.log("Update response:", data);
        },
      });
    } else {
      mutation.mutate(formData, {
        onSuccess: (data) => {
          console.log("Create response:", data);
        },
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center ${
        open ? "" : "hidden"
      }`}
    >
      {isGenActivitiesPost ? (
        <>
          {genActivitesLoading ? (
            <motion.div
              ref={modalRef}
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
            >
              <div className="flex justify-between font-bold">
                <h1 className="text-2xl">TC General Activities Edit</h1>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: "2rem" }}
                  className="text-red-500 cursor-pointer absolute top-2 right-4"
                />
              </div>
              <hr className="mt-2 mb-4" />
              <p>Form Data Loading...</p>
            </motion.div>
          ) : (
            <motion.div
              ref={modalRef}
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
            >
              <div className="flex justify-between font-bold">
                <h1 className="text-2xl">General Activities</h1>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: "2rem" }}
                  className="text-red-500 cursor-pointer absolute top-2 right-4"
                />
              </div>
              <hr className="mt-2 mb-4" />
              <form onSubmit={handleSubmit}>
                <FormInput
                  type="text"
                  name="tc_name"
                  label="TC Name"
                  value={tc_name}
                  disabled
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="procurement_of_project_vehicle"
                    label="Procurement of Project Vehicle"
                    checkedValue={procurement_of_project_vehicle}
                    onChange={handleInputChange(setProcurementOfProjectVehicle)}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="renovation_of_ideas_project_office"
                    label="Renovation of IDEAS Project Office"
                    checkedValue={renovation_of_ideas_project_office}
                    onChange={handleInputChange(
                      setRenovationOfIdeasProjectOffice
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="procurement_of_office_furniture_and_equipment_for_ideas_office"
                    label="Procurement of Office Furniture & Equipment for IDEAS Office"
                    checkedValue={
                      procurement_of_office_furniture_and_equipment_for_ideas_office
                    }
                    onChange={handleInputChange(
                      setProcurementOfOfficeFurnitureAndEquipmentForIdeasOffice
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="procurement_of_diesel_generator"
                    label="Procurement of Diesel Generator"
                    checkedValue={procurement_of_diesel_generator}
                    onChange={handleInputChange(
                      setProcurementOfDieselGenerator
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="provision_of_solar_powered_borehole_and_water_reticulation"
                    label="Provision of Solar Powered Borehole and Water Reticulation"
                    checkedValue={
                      provision_of_solar_powered_borehole_and_water_reticulation
                    }
                    onChange={handleInputChange(
                      setProvisionOfSolarPoweredBoreholeAndWaterReticulation
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="installation_of_solar_power_system"
                    label="Installation of Solar Power System"
                    checkedValue={installation_of_solar_power_system}
                    onChange={handleInputChange(
                      setInstallationOfSolarPowerSystem
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="installation_of_solar_powered_streetlights"
                    label="Installation of Solar Powered Streetlights"
                    checkedValue={installation_of_solar_powered_streetlights}
                    onChange={handleInputChange(
                      setInstallationOfSolarPoweredStreetlights
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="procurement_and_installations_of_ICT_equipment_internet_infrastructure"
                    label="Procurement and Installation of ICT Equipment, Internet/Network Infrastructure"
                    checkedValue={
                      procurement_and_installations_of_ICT_equipment_internet_infrastructure
                    }
                    onChange={handleInputChange(
                      setProcurementAndInstallationsOfICTEquipmentInternetInfrastructure
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="renovation_and_upgrading_of_toilet_facility"
                    label="Renovation and Upgrading of Toilet Facility"
                    checkedValue={renovation_and_upgrading_of_toilet_facility}
                    onChange={handleInputChange(
                      setRenovationAndUpgradingOfToiletFacility
                    )}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="renovation_of_ICT_laboratory"
                    label="Renovation/Upgrading of ICT Laboratory"
                    checkedValue={renovation_of_ICT_laboratory}
                    onChange={handleInputChange(setRenovationOfICTLaboratory)}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="gbv_sensitization"
                    label="GBV Sensitization"
                    checkedValue={gbv_sensitization}
                    onChange={handleInputChange(setGbvSensitization)}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="tvet_sensitization"
                    label="TVET Sensitization"
                    checkedValue={tvet_sensitization}
                    onChange={handleInputChange(setTvetSensitization)}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="renovation_of_classrooms"
                    label="Renovation of Classrooms"
                    checkedValue={renovation_of_classrooms}
                    onChange={handleInputChange(setRenovationOfClassrooms)}
                  />
                </div>
                <div className="mb-4">
                  <FormRadioInput
                    type="radio"
                    name="renovation_of_college_library"
                    label="Renovation of College Library"
                    checkedValue={renovation_of_college_library}
                    onChange={handleInputChange(setRenovationOfCollegeLibrary)}
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </motion.div>
          )}
        </>
      ) : (
        <motion.div
          ref={modalRef}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
        >
          <div className="flex justify-between font-bold">
            <h1 className="text-2xl">General Activities</h1>
            <CloseIcon
              onClick={handleClose}
              style={{ fontSize: "2rem" }}
              className="text-red-500 cursor-pointer absolute top-2 right-4"
            />
          </div>
          <hr className="mt-2 mb-4" />
          <form onSubmit={handleSubmit}>
            <FormInput
              type="text"
              name="tc_name"
              label="TC Name"
              value={tc_name}
              disabled
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="procurement_of_project_vehicle"
                label="Procurement of Project Vehicle"
                checkedValue={procurement_of_project_vehicle}
                onChange={handleInputChange(setProcurementOfProjectVehicle)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="renovation_of_ideas_project_office"
                label="Renovation of IDEAS Project Office"
                checkedValue={renovation_of_ideas_project_office}
                onChange={handleInputChange(setRenovationOfIdeasProjectOffice)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="procurement_of_office_furniture_and_equipment_for_ideas_office"
                label="Procurement of Office Furniture & Equipment for IDEAS Office"
                checkedValue={
                  procurement_of_office_furniture_and_equipment_for_ideas_office
                }
                onChange={handleInputChange(
                  setProcurementOfOfficeFurnitureAndEquipmentForIdeasOffice
                )}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="procurement_of_diesel_generator"
                label="Procurement of Diesel Generator"
                checkedValue={procurement_of_diesel_generator}
                onChange={handleInputChange(setProcurementOfDieselGenerator)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="provision_of_solar_powered_borehole_and_water_reticulation"
                label="Provision of Solar Powered Borehole and Water Reticulation"
                checkedValue={
                  provision_of_solar_powered_borehole_and_water_reticulation
                }
                onChange={handleInputChange(
                  setProvisionOfSolarPoweredBoreholeAndWaterReticulation
                )}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="installation_of_solar_power_system"
                label="Installation of Solar Power System"
                checkedValue={installation_of_solar_power_system}
                onChange={handleInputChange(setInstallationOfSolarPowerSystem)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="installation_of_solar_powered_streetlights"
                label="Installation of Solar Powered Streetlights"
                checkedValue={installation_of_solar_powered_streetlights}
                onChange={handleInputChange(
                  setInstallationOfSolarPoweredStreetlights
                )}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="procurement_and_installations_of_ICT_equipment_internet_infrastructure"
                label="Procurement and Installation of ICT Equipment, Internet/Network Infrastructure"
                checkedValue={
                  procurement_and_installations_of_ICT_equipment_internet_infrastructure
                }
                onChange={handleInputChange(
                  setProcurementAndInstallationsOfICTEquipmentInternetInfrastructure
                )}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="renovation_and_upgrading_of_toilet_facility"
                label="Renovation and Upgrading of Toilet Facility"
                checkedValue={renovation_and_upgrading_of_toilet_facility}
                onChange={handleInputChange(
                  setRenovationAndUpgradingOfToiletFacility
                )}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="renovation_of_ICT_laboratory"
                label="Renovation/Upgrading of ICT Laboratory"
                checkedValue={renovation_of_ICT_laboratory}
                onChange={handleInputChange(setRenovationOfICTLaboratory)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="gbv_sensitization"
                label="GBV Sensitization"
                checkedValue={gbv_sensitization}
                onChange={handleInputChange(setGbvSensitization)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="tvet_sensitization"
                label="TVET Sensitization"
                checkedValue={tvet_sensitization}
                onChange={handleInputChange(setTvetSensitization)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="renovation_of_classrooms"
                label="Renovation of Classrooms"
                checkedValue={renovation_of_classrooms}
                onChange={handleInputChange(setRenovationOfClassrooms)}
              />
            </div>
            <div className="mb-4">
              <FormRadioInput
                type="radio"
                name="renovation_of_college_library"
                label="Renovation of College Library"
                checkedValue={renovation_of_college_library}
                onChange={handleInputChange(setRenovationOfCollegeLibrary)}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default ModalGeneralActivitiesForm;
