import axios from "axios";

const axiosFetchProfile = axios.create({
  baseURL: "https://bkh0kktc-8000.uks1.devtunnels.ms/api/v1", // Base URL for your API
});




export default axiosFetchProfile;
